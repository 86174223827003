import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MediumModal from '../../../../atoms/Modal/MediumModal';
import { useState } from 'react';
import { QuestionCreationFinalCall, QuestionCreationScreen, QuestionCreationScreenCancel } from '../../../../../redux/action';
import MCQ_singleAnswer from './QuestionType.js/MCQ-singleAnswer';
import MCQ_multiAnswer from './QuestionType.js/MCQ-multiAnswer';
import DescriptveText from './QuestionType.js/Descriptive';
import TITA from './QuestionType.js/TITA';
import TITA_specificRange from './QuestionType.js/TITA-specificRange';
import TITA_specificFormat from './QuestionType.js/TITA-specificFormat';
import TITA_specificFormatMulti from './QuestionType.js/TITA-specificFormatMulti';
import MCQ_multiAnswerMulti from './QuestionType.js/MCQ-multiAnswerMulti';
import axios from 'axios';
import { CallWithAuth } from '../../../../../action/apiActions';
import { GET_QUESTIOB_TYPE } from '../../../../../action/apiPath';
import { useNavigate } from 'react-router-dom';


import style from '../../../../../assets/styles/createQuestion.module.css'

const CreatingQuestionSingle = ({ id ,getData }) => {
    // _______ initialization:start _______
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // _______ initialization: end _______


    //_______ redux selector _______
    const selector = useSelector((state) => state.QuestionScreenReducer)
    const basicInfo = useSelector((state) => state.BasicInformationReducer)

    // console.log("basicInfo", basicInfo)
    // MODAL OPEN AND CLOSE 
    const [modalMedium, setModalMedium] = useState(false)
    const [modalData, setModalModalData] = useState({
        "title": "Requires a Review",
        "description": "The question needs to be reviewed by our team before adding it to the Question Bank",
        "cancelButton": "Continue Editing",
        "saveButton": "Send for Review"
    })

    const openModalMedium = () => {
        setModalMedium(true)
    }

    const closeModalMedium = () => {
        setModalMedium(false)
    }

    // total number of answer 
    // const [answers, setAnswers] = useState(["", "", "", ""])
    // const [correntAnswer, setCorrentAnswer] = useState("")

    // correct answer for TITA 
    // const [correntAnswerTita, setCorrentAnswerTita] = useState([])

    // function to add the multiple options 
    // function AddAnswerCall() {
    //     setAnswers(current => [...current, ""])
    // }

    // function to close the multiple options
    // function CloseAnswerCall() {
    //     const newArray = [...answers]
    //     newArray.pop()
    //     setAnswers(newArray)
    // }

    // state of explanation_text and compose text 
    // const [questionDirection, setQuestionDirection] = useState(null)
    // const [explanation_text, setExplanation_text] = useState(null)
    // const [compose_text, setCompose_text] = useState(null)
    // const [explanation_video, setExplanation_video] = useState(null)
    // const [lastIndex, setlastIndex] = useState(null)
    // const [randomise_answers, setRandomise_answers] = useState(false)
    // const [passage_text, setPassage_text] = useState(null)

    // encode tunymce to normal text 
    const decode = (item, key, index) => {

        if (item.length > 0) {
            // Your HTML content in a variable
            const htmlString = item;

            // Create a new DOMParser
            const parser = new DOMParser();

            // Parse the HTML string
            const parsedHtml = parser.parseFromString(htmlString, 'text/html');

            // Access the innerHTML property of the first child
            const innerHTML = parsedHtml.body.firstChild.innerHTML;

            // Store innerHTML in a variable
            const myVariable = innerHTML;

            // Now you have the innerHTML content (i.e., "helljhabdsaho") saved in the `myVariable` variable
            key(myVariable);
        } else {

        }


    }

    // const [questionNumber, setQuestionNumber] = useState([])

    // body of finalcall API 
    // const body = {
    //     "question_type_id": selector.questiontype.question_type_id.id,
    //     "question_direction": questionDirection,
    //     "compose_question": compose_text,
    //     "options": answers,
    //     "correct_answer": selector.questiontype.question_type_id.id === 4 || selector.questiontype.question_type_id.id === 5 ? correntAnswerTita.toString() : correntAnswer.toString(),
    //     "last_option_idx": lastIndex,
    //     "randomise_answers": randomise_answers,
    //     "explaination_video": explanation_video,
    //     "explaination_text": explanation_text
    // }


    const [bodyformultiquestion, setbodyformultiquestion] = useState([
        {
            "question_type_id": selector.questiontype.question_type_id.id,
            "question_short_identifier": getData?.question_short_identifier || null,
            "compose_question": getData?.compose_question || null,
            "options": getData?.options || null,
            "correct_answer": getData?.correct_answer || null,
            "last_option_idx": null,
            "randomise_answers": getData?.randomise_answers || false,
            "explaination_video": getData?.explaination_video || null,
            "explaination_text": getData?.explaination_text || null,
            "answer_format":  getData?.answer_format || null,
            "task_label": null,
            "video_id": getData?.video_id || null,
            // "difficulty": basicInfo.difficulty,
            // "question_type": selector.questiontype.question_type_id.id,
            // "format": basicInfo.format
        }
    ])
    const [finalbody, setfinalbody] = useState(null)
    const [question, setQuestion] = useState([0, 1])
    const [questionbutton, setQuestionButton] = useState(0)

    // function to add the multiple QUESTIONS AND ANSWERS 
    // const AddBodyQuestion = () => {
    //     const updatebody = [...bodyformultiquestion];
    //     updatebody[questionbutton] = {
    //         "question_type_id": questionTypeScreen,
    //         "question_direction": questionDirection,
    //         "compose_question": compose_text,
    //         "options": answers,
    //         "correct_answer": selector.questiontype.question_type_id.id === 4 || selector.questiontype.question_type_id.id === 5 ? correntAnswerTita.toString() : correntAnswer.toString(),
    //         "last_option_idx": lastIndex,
    //         "randomise_answers": randomise_answers,
    //         "explaination_video": explanation_video,
    //         "explaination_text": explanation_text
    //     };
    //     setbodyformultiquestion(updatebody);
    // }

    // function to make final body 
    // const FinalBodyCreate = () => {
    //     const finalBody = {
    //         "question_group": bodyformultiquestion,
    //         "passage": passage_text
    //     }
    //     setfinalbody(finalBody)
    // }

    // to get the final data for multiple questions of the TITA and others 
    // useEffect(() => {
    //     FinalBodyCreate()
    // }, [bodyformultiquestion])


    // effect to add answer call for multiple question data 
    // useEffect(() => {
    //     AddBodyQuestion()
    // }, [questionDirection, compose_text, answers, correntAnswerTita, correntAnswer, lastIndex, randomise_answers, explanation_video, explanation_text])

    // _______ TO close MULTIPLE QUESTION AND ANSWER for same screen _______
    const closeQuestion = () => {
        setQuestion(question.filter((items, i) => items !== questionbutton))
        setQuestionButton(questionbutton - 1)
    }


    // ________ get category from API and grouped in sequence ________
    const [questionCategory, setQuestionCategory] = useState([])
    const [slectedquestionCategory, setSelectedQuestionCategory] = useState(0)

    const GetQuestionTypeCategory = async () => {
        const category = await CallWithAuth("GET", GET_QUESTIOB_TYPE)
        if (category.status && category.res.status === 200) {
            setQuestionCategory(category.res.data)
        }
    }

    useEffect(() => {
        GetQuestionTypeCategory()
    }, [])


    {/*________question type getting from screen 2.1 and dropdown for changing for question________*/ }
    const [questionTypeScreen, setQuestionTypeScreen] = useState(0)

    const SetSelectQuestionType = (e) => {
        const updatebody = [...bodyformultiquestion];
        updatebody[questionbutton] = {
            "question_type_id": e,
            "question_short_identifier": null,
            "compose_question": null,
            "options": null,
            "correct_answer": null,
            "last_option_idx": null,
            "randomise_answers": false,
            "explaination_video": null,
            "explaination_text": null,
            "answer_format": null,
            "task_label": null,
            // "difficulty": basicInfo.difficulty,
            // "question_type": e,
            // "format": basicInfo.format
        };
        setbodyformultiquestion(updatebody);
    }


    useEffect(() => {
        setfinalbody(bodyformultiquestion[0])
    }, [bodyformultiquestion])


    useEffect(() => {
        setQuestionTypeScreen(selector.questiontype?.question_type_id?.id)
    }, [selector])





// console.log(finalbody)

     // _____________ MODAL OPEN AND CLOSE _____________
     const [modalMediumCancel, setModalMediumCancel] = useState(false)
     const [modalDataCancel, setModalModalDataCancel] = useState({
         "title": "Are You Sure, Do You Want To Cancel Question Creation?",
         "description": "Your Current Changes Will Not Be Saved And You Will Be Redirected To Question Listing ",
         "cancelButton": "Continue Editing",
         "saveButton": "Yes, Cancel"
     })
 
     const openModalMediumCancel = () => {
         setModalMediumCancel(true)
     }
 
     const closeModalMediumCancel = () => {
         setModalMediumCancel(false)
     }


    // cancel button to send the user to question-bank 
    const Cancel = () => {
        navigate('/question-bank')
        dispatch(QuestionCreationScreenCancel())
    }


    // console.log("getData==>>",bodyformultiquestion)
    // console.log("getData==>>",bodyformultiquestion)

    return (
        <>


        
                 {/* question type getting from screen 2.1 */}
                 <div className={style.question_creating} style={{ height: "5%" }}>
                <div>
                    <div className={style.question_creating_title}>
                        <span>{selector.questiontype.question_type_id.category} - {selector.questiontype.question_type_id.name} </span>

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9201 8.94922L13.4001 15.4692C12.6301 16.2392 11.3701 16.2392 10.6001 15.4692L4.08008 8.94922" stroke="#212529" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                </div>
            </div>


            {/*_______  QUESTION TYPE SCREEN ACCORDING TO SELECTOR _______  */}
            {bodyformultiquestion[questionbutton]?.question_type_id === 1 ? <MCQ_singleAnswer body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {bodyformultiquestion[questionbutton]?.question_type_id === 2 ? <MCQ_multiAnswer body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {bodyformultiquestion[questionbutton]?.question_type_id === 3 ? <TITA body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {bodyformultiquestion[questionbutton]?.question_type_id === 4 ? <TITA_specificRange body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {bodyformultiquestion[questionbutton]?.question_type_id === 5 ? <TITA_specificFormat body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {bodyformultiquestion[questionbutton]?.question_type_id === 6 ? <DescriptveText body={bodyformultiquestion} questionbutton={questionbutton} setbodyformultiquestion={setbodyformultiquestion} /> : ""}
            {/* {selector.questiontype.question_type_id.id === 6 ? <TITA_specificFormatMulti questionbutton={questionbutton} setPassage_text={setPassage_text} setQuestionButton={setQuestionButton} setQuestion={setQuestion} question={question} correntAnswerTita={correntAnswerTita} setCorrentAnswerTita={setCorrentAnswerTita} correntAnswer={correntAnswer} explanation_text={explanation_text} setQuestionDirection={setQuestionDirection} setRandomise_answers={setRandomise_answers} answers={answers} AddAnswerCall={AddAnswerCall} setExplanation_video={setExplanation_video} setAnswers={setAnswers} setCorrentAnswer={setCorrentAnswer} CloseAnswerCall={CloseAnswerCall} setlastIndex={setlastIndex} setCompose_text={setCompose_text} setExplanation_text={setExplanation_text} decode={decode} /> : ""}
            {selector.questiontype.question_type_id.id === 7 ? <MCQ_multiAnswerMulti questionbutton={questionbutton} setQuestionButton={setQuestionButton} setQuestion={setQuestion} question={question} correntAnswerTita={correntAnswerTita} setCorrentAnswerTita={setCorrentAnswerTita} correntAnswer={correntAnswer} explanation_text={explanation_text} setQuestionDirection={setQuestionDirection} setRandomise_answers={setRandomise_answers} answers={answers} AddAnswerCall={AddAnswerCall} setExplanation_video={setExplanation_video} setAnswers={setAnswers} setCorrentAnswer={setCorrentAnswer} CloseAnswerCall={CloseAnswerCall} setlastIndex={setlastIndex} setCompose_text={setCompose_text} setExplanation_text={setExplanation_text} decode={decode} /> : ""} */}



            {/* SAVE AND CONTINUE  */}
            <div className={style.saveButton_questionCreation}>
                <div className={` ${style.cancel} ${style.pointer}`}  onClick={() => openModalMediumCancel()}>
                    Cancel
                </div>
                <div>
                    <button className={style.blue_button} onClick={() => openModalMedium()}>Save & Continue</button>
                </div>
            </div>
            {/* SAVE AND CONTINUE  */}


            {/* MEDIUM MODAL CALL with dispatch function  */}
            <MediumModal modalData={modalData} forwardButton={() => dispatch(QuestionCreationFinalCall(finalbody, id))} modalMedium={modalMedium} openModalMedium={openModalMedium} closeModalMedium={closeModalMedium} />

            <MediumModal modalMedium={modalMediumCancel} forwardButton={() => Cancel()} openModalMedium={openModalMediumCancel} closeModalMedium={closeModalMediumCancel} modalData={modalDataCancel} />


        </>
    )
}

export default CreatingQuestionSingle