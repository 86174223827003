import React from "react";
import { Editor } from "@tinymce/tinymce-react";

function TinyMCE({ defaultValue = "", onChange, disabled = false }) {
    return (
        <Editor 
            tinymceScriptSrc="https://manual-upload-assets.s3.ap-south-1.amazonaws.com/tinymce/tinymce.min.js"
            apiKey="gpl"
            value={defaultValue}
            width="100%"
            readOnly={disabled}
            onEditorChange={(_text, editor) => {
                if (onChange) {
                    onChange(editor.getContent({ format: "html" }));
                }
            }}
        />
    )
}

export default TinyMCE;
